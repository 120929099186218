// Adds a border between each cell
// How to unhack: Move to Primer CSS
// When moving, also replace .discussion-sidebar-item
.BorderGrid {
  display: table;
  width: 100%;
  margin-top: -$spacer-3;
  margin-bottom: -$spacer-3;
  table-layout: fixed;
  border-collapse: collapse;
  // stylelint-disable-next-line primer/borders
  border-style: hidden;

  .BorderGrid-cell {
    padding-top: $spacer-3;
    padding-bottom: $spacer-3;
  }
}

.BorderGrid--spacious {
  margin-top: -$spacer-4;
  margin-bottom: -$spacer-4;

  .BorderGrid-cell {
    padding-top: $spacer-4;
    padding-bottom: $spacer-4;
  }
}

.BorderGrid-row {
  display: table-row;
}

.BorderGrid-cell {
  display: table-cell;
  border: $border-width $border-style var(--borderColor-muted, var(--color-border-muted));
}
